import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/main/store/model/state.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PostService } from 'src/app/main/services/post.services';
import moment from 'moment';
import { DOCUMENT } from '@angular/common';
import { MessageService } from 'primeng/api';
import {
  Post,
  Comment,
  DialogEvent,
  UserDetails,
  App,
} from 'src/app/main/types/main.types';
import { ScrollPanel } from 'primeng/scrollpanel';
import { postOptions } from 'src/app/main/utilities/overlayHelper';
import { TieredMenu } from 'primeng/tieredmenu';
import { Observable, Subject, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { activityMap, visibilityMap } from 'src/app/main/utilities';
import { DEFAULT_HOME } from 'src/app/main/utilities';

@Component({
  selector: 'app-home-timeline-view',
  templateUrl: './home-timeline-view.component.html',
})
export class HomeTimelineViewComponent implements OnInit {
  postView: Post;

  isLoading = true;

  currentPage = 1;

  postId: string;

  comments: Comment[] = [];

  commentId: string;

  moment = moment;

  DEFAULT_HOME = DEFAULT_HOME;

  postOptions = postOptions;

  activityMap = activityMap;

  visibilityMap = visibilityMap;

  itemFor: string;

  isLike = false;

  commentText: string;

  shareLinkEvent: Subject<boolean> = new Subject<boolean>();

  userPickerVisible = false;

  userInfo: UserDetails;

  userDetail$: Observable<UserDetails>;

  appState$: Observable<App>;

  userPickerEvent: Subject<any> = new Subject<any>();

  dialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  postDialogEvent: Subject<DialogEvent> = new Subject<DialogEvent>();

  showDescription = false;

  isDark = false;

  selectedUser: number[] = [];

  dummyDescription = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries`;

  @ViewChild('menu', { static: false }) menuRef: TieredMenu;

  @ViewChild('scrollPanel', { static: false }) scrollPanelRef: ScrollPanel;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    private postService: PostService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private store: Store<AppState>,
  ) {}

  ngOnInit(): void {
    this.postId = this.route.snapshot.paramMap.get('id') as string;
    this.userDetail$ = this.store
      .select((store) => store.user)
      .pipe(
        tap((user: UserDetails) => {
          this.userInfo = user;
        }),
      );
    this.userDetail$.subscribe();
    this.getAppState();
    this.getPostDetail(this.postId);
  }

  getAppState() {
    this.appState$ = this.store
      .select((store) => store.app)
      .pipe(
        tap((appState: App) => {
          this.isDark = appState.isDark;
        }),
      );
    this.appState$.subscribe();
  }

  getPostDetail(id: string | null) {
    this.postService.getPost(id).subscribe({
      next: (data: any) => {
        console.log('Request to get posts succeeded');
        if (data.type === 'REPOST') {
          data.refPost.parameters.forEach((p: any) => {
            if (data.refPost.content)
              data.refPost.content = data.refPost.content.replace(
                new RegExp(`{{(${p.key})}}`, 'gi'),
                p.type === 'LOCATION'
                  ? `<i><strong>${p.value}</strong></i>`
                  : `<strong>${p.value}</strong>`,
              );
          });
        } else {
          data.parameters.forEach((p: any) => {
            if (data.content)
              data.content = data.content.replace(
                new RegExp(`{{(${p.key})}}`, 'gi'),
                p.type === 'LOCATION'
                  ? `<i><strong>${p.value}</strong></i>`
                  : `<strong>${p.value}</strong>`,
              );
          });
        }
        this.postView = data;
        this.getComments();
      },
      error: (err) => {
        console.error('Request to get get posts errored out.');
        this.isLoading = false;

        if (err.status === 403 || err.status === 404) {
          this.router.navigate(['/404'], { skipLocationChange: true });
        }
      },
    });
  }

  onLikeUnlike(id: string) {
    const method = !this.postView.reacted ? 'like' : 'unlike';
    this.postService[method](id).subscribe({
      next: (data: any) => {
        this.postView.reacted = !this.postView.reacted;
        this.postView.reacted && (this.postView.totalReactionCount += 1);
        !this.postView.reacted && (this.postView.totalReactionCount -= 1);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Post Reaction',
          detail: err.error.message,
        });
      },
    });
  }

  getComments() {
    this.postService.getComments(this.postId, this.currentPage - 1).subscribe({
      next: (data: any) => {
        this.comments = [...this.comments, ...data];
        this.isLoading = false;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Post',
          detail: err.error.message,
        });
      },
    });
  }

  deleteComment(commentId: string) {
    this.postService.deleteComment(this.postId, commentId).subscribe({
      next: () => {
        this.comments = this.comments.filter(
          (c: Comment) => c.id !== commentId,
        );
        this.postView.totalCommentCount -= 1;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Comment',
          detail: err.error.message,
        });
      },
    });
  }

  comment(e: any) {
    if (this.commentText.trim() !== '') {
      this.postService.comment(this.postId, this.commentText).subscribe({
        next: (data: any) => {
          this.comments.unshift(data);
          this.postView.totalCommentCount += 1;
          this.commentText = '';
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Post',
            detail: err.error.message,
          });
        },
      });
    }
  }

  onMenuToggle(e: any, item: string, cId = '') {
    this.itemFor = item;
    this.commentId = cId;
    this.menuRef.toggle(e);
  }

  deletePost() {
    this.postService.deletePost(this.postId as string).subscribe({
      next: (data: any) => {
        this.messageService.add({
          severity: data?.error ? 'error' : 'success',
          summary: 'Post',
          detail: data?.error
            ? 'Post deletion failed'
            : 'Post Deleted Successfully',
        });
        this.router.navigate([DEFAULT_HOME, 'timeline']);
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Post',
          detail: err.error.message,
        });
      },
    });
  }

  onTieredMenuItemClick(action: string) {
    console.log(action);
    switch (action) {
      case 'post_delete':
        this.deletePost();
        break;
      case 'share':
        this.shareLinkEvent.next(!0);
        break;
      case 'comment_delete':
        this.deleteComment(this.commentId);
        break;
      case 'share_to_friend':
        this.userPickerVisible = true;
        this.userPickerEvent.next('onLoad');
        break;
      case 'repost_as_it_is':
        this.repostAsitIs();
        break;
      case 'view_huddle':
        this.router.navigate(['/huddle', 'view', this.postView.posterObj.id]);
        break;
      default:
        this.postDialogEvent.next({
          visible: true,
          dialogType: 'POST',
          post: this.postView,
        });
        break;
    }
  }

  repostAsitIs() {
    this.postService
      .createPost({
        taggedUsers: {},
        taggedLocations: {},
        taggedHashes: {},
        reposting: true,
        refPostId: this.postView.id,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Repost',
            detail: 'Reposted Successfully',
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Repost',
            detail: err.error.message,
          });
        },
      });
  }

  onMouseEvent(e: any) {
    e.type === 'mouseout' && e.currentTarget.classList.remove('row-active');
    e.type === 'mouseover' && e.currentTarget.classList.add('row-active');
  }

  onScrolledDown() {
    this.currentPage++;
    this.getComments();
  }

  getProfileImage(id: number) {
    return environment.apiUrl + '/api/user/' + id + '/pic';
  }

  getCoverPhoto(id: number) {
    return environment.apiUrl + '/api/huddles/' + id + '/cover';
  }

  getCommunityCoverPhoto(id: number) {
    return environment.apiUrl + '/api/communities/' + id + '/cover';
  }

  getCommunityLogo(id: number) {
    return environment.apiUrl + '/api/communities/' + id + '/logo';
  }

  onClickLike() {
    this.dialogEvent.next({
      visible: true,
      post: this.postView,
      dialogType: 'USER',
    });
  }

  onList(e: UserDetails) {
    this.selectedUser.push(e.id);
  }

  onRemove(e: number) {
    this.selectedUser = this.selectedUser.filter((s) => s !== e);
  }

  onClickInvite(event: any) {
    this.postService
      .shareWithUsers({
        objectId: '' + this.postId,
        objectType: 'POST',
        userIds: this.selectedUser,
      })
      .subscribe({
        next: (data: any) => {
          this.messageService.add({
            severity: data.error ? 'error' : 'success',
            summary: 'Share to Friend',
            detail: data.message,
          });
          this.userPickerVisible = false;
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Share to Friend',
            detail: err.error.message,
          });
        },
      });
  }
}
